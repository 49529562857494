import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';

import './src/styles/global.css';

/**
 * This function is used to wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * @param {ReactNode} element The “Page” React Element built by Gatsby
 */

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
	return <>{element}</>;
};
